import React from 'react';
import {BsGithub, BsLinkedin} from 'react-icons/bs';
import {FaFacebookF} from 'react-icons/fa';

export const SocialMedia = () => {
	return (
		<div className='app__social'>
			<a href='https://github.com/Carolina-Toren' target='_blank'>
				<BsGithub />
			</a>
			<a href='https://www.linkedin.com/in/carolina-toren' target='_blank'>
				<BsLinkedin />
			</a>
			<a href='https://www.facebook.com/carolina.toren' target='_blank'>
				<FaFacebookF />
			</a>
		</div>
	);
};

export default SocialMedia;
