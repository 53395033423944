import email from '../images/email.png';
import mobile from '../images/mobile.png';
import javascript from '../images/javascript.png';
import react from '../images/react.png';
import sass from '../images/sass.png';
import profile from '../images/profile.png';
import circle from '../images/circle.svg';
import logo from '../images/logo.png';

export default {
	email,
	mobile,
	javascript,
	react,
	sass,
	profile,
	circle,
	logo,
};
